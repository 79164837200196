import { configBuilder } from "ccui-framework";
import { commonConfig } from "../../../src/config/partner";

const Config = {
  wayfair: {
    ...commonConfig,
    reduxLogger: false,
    //layout
    layoutVersion: 3,
    //layout botton images for layout v3
    layoutOverlayImagesLeftImg:
      "https://cdn.cardcash.com/website/partners/wayfair/Left@2x (1).png",
    layoutOverlayImagesRightImg:
      "https://cdn.cardcash.com/website/partners/wayfair/Right@2x.png",

    exchangeMastTextPartOneV1: "Trade Gift Cards for a",
    exchangeMastTextPartTwoV1: "Wayfair Gift Card",

    soundGoodAddAnotherCardLinkTxt: "+ Add Another Card",
    monthYearTextPlaceHolder: "MM/YY",
    cvvTextPlaceHolder: "CVV",
    orderCompleteCardInfoTxt: {
      text: (config) =>
        `If your trade order is accepted, your Wayfair Gift Card will be emailed to you. This process may take up to one business day."`,
    },

    headerBackTo: true,
    tloMissingNumberLink: "Missing Number? Learn why",
    identityTloTitle: "Choose your phone number",
    identityTloPassCodeTitle: "Enter the code ",
    identityTloErrorTitle: "Phone Number Information",
    identityTloExpiredTitle: "Expired",

    tloVerifyBtnTxt: "Continue",

    tloErrorTxt: `<p>The phone numbers we list are pulled from trusted third parties who provide numbers associated with your identity.
    We can not accept any other phone numbers for verification.</p>  <p> Security is of paramount importance to CardCash and we apologize if you are unable to continue. </p>`,

    // Verification
    verificationType: "tlo",
    // meta
    paymentType: "Gift Card",
    aOrAn: "a",
    partnerBrand: "Wayfair",
    partnerURL: "http://www.wayfair.com/",
    partnerEmail: "wayfair@cardcash.com",
    giftCardTerms:
      "https://www.wayfair.com/customerservice/general_info.php#terms",
    termsAndConditionsBuilder: (config) =>
      `Restrictions apply, see  <a href='${config.giftCardTerms}'>Terms and Conditions</a>`,

    cardDetailsTitle: "Provide Gift Card Details",
    identityCardInfoTitle: "Enter your credit card info",
    identityBillingInfoTitle: "Enter your billing info",

    // social
    facebookURL: "https://www.facebook.com/CardCash/",
    twitterURL: "https://twitter.com/CardCash",
    youTubeURL: null,
    instagramURL: "https://www.instagram.com/cardcash",
    googleURL: null,
    linkedInURL: null,
    pinterestURL: "https://www.pinterest.com/cardcash/",

    // api
    googleAnalytics: "",
    x_cc_app: "+0V/hwy6K",
    baseURL: "https://production-api.cardcash.com/",

    // header
    headerMenuItemOrder: ["contactItem", "faqItem"],
    headerMenuFaqItem: {
      text: (config) => "FAQ",
    },

    headerLogoOrder: ["headerLogoBrand", "headerLogoCC"],
    headerLogoBrandIcon:
      "https://s3.amazonaws.com/cdn.cardcash.com/website/partners/wayfair/logo1.png",
    soundsGoodSaveButtonTxt: "Get Offer",
    // steps
    stepsVersion: 3,
    stepsV3Text: [
      [
        "Get Offer",
        "Tell us about the brand and balance and get an instant offer.",
      ],
      ["Provide Details", "Enter the details for your gift card."],
      [
        "Verify Your Identity",
        "We’ll use this information to decide if we can accept your card.",
      ],
      [
        "Receive your Wayfair Gift Card",
        "Your digital Wayfair Gift Card will be emailed to you within 1 business day.",
      ],
    ],

    // offer
    offerButtonTxt: "Get Instant Offer",
    offerButtonTxtMany: "Get Instant Offer",
    offerBalanceInputPlaceHolder: "$ Remaining Balance",

    // card number and pin input boxs
    cardNumberPlaceHolderText: "Gift Card Number",
    cardPinPlaceHolderText: "PIN",

    // Have another card? Add it here?
    haveAnotherCardShowPopup: false,

    sellInstructionsDesktopTitle: "How It Works?",
    exchangeMastTextPartThreeV1: "Over 200 Brands Accepted!",

    cardCashUrl: "https://www.cardcash.com",
    tloResendCodeLinkTxt: "Resend Code",
    orderCompleteShowBonusTxt: false,

    theme: {
      background_color: "#edebe9",
      hyperlink_color: "#7f187f",
      primary_color: "#7f187f",
      secondary_color: "#801880",
      button_background_color: "#7f187f",
      button_font_color: "#FFFFFF",
      sell_instructions_bg_color: "#6b146b",
      progress_bar: "#fecd25",
    },
  },

  "test-wayfair": {
    googleAnalytics: "",
    x_cc_app: "U9Sqj4x4V",
    baseURL: "https://integration-api.cardcash.com/",
    cardCashUrl: "https://test.cardcash.com",
    reduxLogger: true,
  },

  localhost: {
    googleAnalytics: "",
    x_cc_app: "U9Sqj4x4V",
    baseURL: "https://integration-api.cardcash.com/",
    cardCashUrl: "https://test.cardcash.com",
    reduxLogger: true,
  },
};

export const configModule = configBuilder("wayfair", Config);
